import { getCurrentInstance, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import Util from '@/common/utils/Util'

export const componentState = () => {
  const { proxy } = getCurrentInstance()
  const state = reactive({
    result: {}
  })
  const { dispatch, getters } = useStore()
  const route = useRoute()
  const router = useRouter()

  const init = async () => {
    const startIndex = route.fullPath.indexOf('?')
    const jsonStr = route.fullPath.substr(startIndex + 1)
    const params = JSON.parse(decodeURIComponent(jsonStr))
    if (params.loginType == '3') {
      await fnCallback(params)
    } else {
      window.opener.parent.postMessage(
        {
          source: 'snsPopResult',
          params: params
        },
        '*'
      )
      handlerClose()
    }
  }

  const fnCallback = async params => {
    state.result = proxy.$_.cloneDeep(params)
    if (params.resultCode === '0000') {
      // 로그인한 회원데이터
      await dispatch('user/signIn', {
        authorization: params.authorization,
        data: params
      })
      await dispatch('user/updateIsAuto', {
        isAuto: proxy.$ConstCode.BOOLEAN_VALUE.TRUE
      })
      await fnCheckRequestExtension()
      // await router.replace({ path: '/main', query: { isRoot: 1 } })
    } else if (params.resultCode === '0006') {
      const registerForm = Object.assign(getters['user/getRegisterForm'])
      registerForm.loginType = Number(params.loginType)
      // registerForm.nick = params.nick
      registerForm.id = params.snsId
      await dispatch('user/updateRegisterForm', { registerForm })
      await router.push({ path: '/regist/terms' })
    } else {
      if (!proxy.$Util.isEmpty(params.resultCode)) {
        alert(params.resultMsg)
      } else {
        alert('인증에 실패하였습니다.')
      }
    }
  }

  const fnCheckRequestExtension = async () => {
    if (
      !proxy.$Util.isEmpty(state.result.autoExtensionPopup) &&
      state.result.autoExtensionPopup
    ) {
      await dispatch('user/updateIsAutoExtensionPopup', {
        isAutoExtensionPopup: true
      })
    }
    await router.push({ path: '/main', query: { isRoot: 1 } })
  }

  const handlerClose = () => {
    window.addEventListener('message', function({ data }) {
      if (!Util.isEmpty(data.source) && data.source === 'snsPopClose') {
        // sns 콜백 message 처리
        window.self.close()
      }
    })
  }

  init()

  return { init, handlerClose }
}
